<template>
  <!-- @TODO Delete file and related -->
  <div
    :class="{'is-deactivated': !data.active}"
    class="he-ui-statistics-phase d-flex flex-column justify-content-between position-relative align-items-center h-100">
    <!-- <div
      v-if="data.showMarkUp"
      class="mark-up" /> -->
    <div
      class="he-ui-statistics-image-wrapper position-relative">
      <img :src="data.src" :alt="data.alt" />
      <div
        v-if="data.active"
        class="he-ui-statistics-image-tag position-absolute d-flex justify-content-center align-items-center">
        {{ data.tag }}
      </div>
    </div>
    <TimeCircle
      v-if="showTimeCircle"
      :data="data.value"
      template="default" />
    <Button
      v-if="showButton"
      class="he-ui-overlay-content-button mt-0"
      template="turquoise"
      rounded
      :icon="buttonIcon"
      :text="$t(buttonText)"
      :onClick="onClickButton"/>
  </div>
</template>

<script>
import Button from '@/components/MainButton'
import TimeCircle from '@/components/statistics/TimeCircle'

export default {
  components: {
    Button,
    TimeCircle
  },
  props: {
    data: {
      type: Object
    },
    showTimeCircle: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: 'main.show_more'
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    onClickHandler: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    onClickButton () {
      this.onClickHandler(this.data.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-statistics-phase {
  &.is-deactivated {
    opacity: .5;
  }
}
// .mark-up {
//   content: '';
//   background: white;
//   width: calc(100% + 38%);
//   height: 2px;
//   position: absolute;
//   left: 0;
//   top: 50%;
// }
.he-ui-statistics-image-wrapper {
  padding: 5%;
  width: 12.8rem;
  height: 12.8rem;
  z-index: 1;
  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 1180px) {
    width: 10rem;
    height: 10rem;
  }
}
.he-ui-statistics-image-tag {
  background: white;
  border-radius: 0.3rem;
  bottom: -0.8rem;
  width: 100%;
  font-size: 1.4rem;
  left: 0;
  height: 25%;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  @media (max-width: 1180px) {
    font-size: 1.2rem;
  }
}
</style>
